import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import Text from '../components/text/Text'
import Headline from '../components/headline/Headline'
import Container from '../components/container/Container'
import RandomImages from '../components/randomImages/RandomImages'
import Draggable from '../components/draggable/Draggable'
import Button from '../components/button/Button'
import Helmet from 'react-helmet'

const Page = ({data: {investors}, pageContext}) => {

    if (!investors) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    const {headline, text, random_images, button} = investors.data

    let draggableButton
    if (button && button.html) {
        const href = button.html.match(/href="([^"]*)/)[1]
        draggableButton = (
            <Draggable
                left={Math.random()}
                top={Math.random()}
            >
                <Button to={href}>{button.text}</Button>
            </Draggable>
        )
    } else {
        draggableButton = null
    }

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >

            <Helmet>
                <title>{`Aquilas — ${headline.text}`}</title>
            </Helmet>

            <Headline headline={headline}/>
            <Container>
                <Text text={text}/>
            </Container>

            <RandomImages images={random_images}/>

            {draggableButton}

        </Layout>
    )
}

export const query = graphql`
query investorsQuery($lang: String!)  {
  investors:prismicInvestors(lang:{eq:$lang }) {
    data {
      headline {
        text
      }
      text {
        html
      }
      random_images {
        image {
          url
          dimensions {
            width
            height
          }
        }
      }
      button {
        text
        html
      }
    }
  }
}
`

export default Page